<template>
  <div class="bg-white mx-2 p-8 border">
    <div class="mb-8 d-flex">
      <div class="d-md-flex flex-grow-1 align-items-center">
        <div
          v-if="headshot"
          class="w-32 h-32 rounded-circle bg-dark overflow-hidden mb-8 mb-md-0 w-md-16 h-md-16 mx-auto ml-md-0 mr-md-5"
        >
          <img class="w-100 h-100 object-cover" :src="headshot" :alt="name" />
        </div>
        <div class="position-relative text-left">
          <div class="leading-tight">
            <span class="mb-0 text-3xl font-weight-bold">
              {{ name }}
            </span>
          </div>
          <span class="text-muted">
            <span v-if="title">{{ title }}</span>
            <span v-if="title && companyName">,{{ " " }}</span>
            <span v-if="companyName">{{ companyName }}</span>
          </span>
        </div>
      </div>
      <div class="d-none d-md-block flex-shrink-0">
        <div class="embed-responsive embed-responsive-21by9 w-40">
          <div class="embed-responsive-item">
            <slot />
          </div>
        </div>
      </div>
    </div>
    <div class="position-relative">
      <p
        class="mb-0 text-3xl text-blue-500 font-realist-wide font-weight-bold leading-tight"
      >
        &ldquo;{{ quote }}&rdquo;
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialCard",
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    headshot: {
      type: String
    },
    companyName: {
      type: String
    },
    companyLogo: {
      type: String
    },
    quote: {
      type: String,
      required: true
    }
  }
};
</script>
